import {
  HttpEvent,
  HttpHandler,
  HttpHandlerFn,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * @deprecated use jsonLdInterceptor function instead
 * will be removed in v14
 */
export class JsonLdInterceptor implements HttpInterceptor {
  /**
   * @inheritDoc
   *
   * This interceptor adds the json-ld accept header to all specified endpoints.
   */
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let request: HttpRequest<unknown> = req.clone({
      headers: req.headers.set('accept', 'application/ld+json, application/json'),
    });

    return next.handle(request);
  }
}

/**
 * @usageNotes
 * ```typescript
 * await bootstrapApplication(RootComponent, {
 *   providers: [
 *     provideHttpClient(
 *       withInterceptors([jsonLdInterceptor, authInterceptor])
 *     ),
 *   ]
 * });
 * ```
 */
export function jsonLdInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  let update = req.headers;
  if (!req.headers.has('accept')) {
    update = req.headers.set('accept', 'application/ld+json, application/json');
  }
  let request: HttpRequest<unknown> = req.clone({ headers: update });
  return next(request);
}
