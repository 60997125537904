import { ENVIRONMENT_INITIALIZER, importProvidersFrom } from '@angular/core';
import { IonicStorageModule, Storage, StorageConfig } from '@ionic/storage-angular';

export function provideIonicStorage(storageConfig?: StorageConfig) {
  return [
    importProvidersFrom(IonicStorageModule.forRoot(storageConfig)),
    {
      provide: ENVIRONMENT_INITIALIZER,
      useFactory: (storage: Storage) => {
        return () => storage.create();
      },
      multi: true,
      deps: [Storage],
    },
  ];
}
