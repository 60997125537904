<div class="icon">
  <img alt="notification icon" ngSrc="{{ notification().icon }}" width="16" height="16" />
</div>
<ion-button class="close" size="small" fill="clear" (click)="dismiss()">
  <fa-icon slot="icon-only" [icon]="faXmark"></fa-icon>
</ion-button>
<div class="application-name">{{ source() }}</div>
<div class="timestamp">&bull; {{ timeStamp() | date }}</div>
<ion-button size="small" fill="clear" class="expand" (click)="isExpanded.set(!this.isExpanded())">
  <fa-icon slot="icon-only" [icon]="isExpanded() ? faChevronUp : faChevronDown"></fa-icon>
</ion-button>
<h3 role="heading" class="title">{{ notification().title }}</h3>
<div
  role="textbox"
  class="body"
  [class.expanded]="isExpanded()"
  [@expanded]="isExpanded() ? 'open' : 'closed'"
>
  {{ notification().body }}
</div>
<div class="actions" aria-label="actions">
  @for (action of notification().data?.actions ?? []; track action.title) {
    <ion-button color="secondary" size="small" (click)="triggerAction(action)" fill="clear">
      {{ action.title }}
    </ion-button>
  }
</div>
