import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { NotificationsEffects } from './+state/notifications.effects';
import { NotificationsService } from './notifications.service';

export function provideNotifications(): Provider | EnvironmentProviders[] {
  if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    Notification.requestPermission();
  }
  return [provideEffects(NotificationsEffects), NotificationsService];
}
