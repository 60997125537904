import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { mapValues } from 'lodash-es';
import { filter, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsComponent } from '../notifications/notifications.component';
import { notificationsActions } from './notifications.actions';

@Injectable()
export class NotificationsEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly errorHandler = inject(SentryErrorHandler);

  private readonly router = inject(Router);

  private readonly overlay = inject(Overlay);

  private overlayRef = this.overlay.create({
    positionStrategy: this.overlay.position().global().bottom('32px').right('32px'),
    backdropClass: 'cdk-overlay-transparent-backdrop',
    disposeOnNavigation: true,
  });

  public readonly pushNotificationToNotificationApi = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notificationsActions.showNotification),
        filter((action) => !action.foregroundOnlyNotification),
        tap((action) => {
          const notification = new Notification(action.title, {
            ...action.options,
            timeStamp: new Date().toISOString(),
          } as NotificationOptions);
          notification.addEventListener('click', () => {
            if (notification.data.actions?.[0].action) {
              this.router.navigate(['/', notification.data.actions?.[0].action]);
            }
          });
          return notification;
        }),
      ),
    { dispatch: false },
  );

  private readonly closeOnBacklogClick = this.overlayRef
    .backdropClick()
    .pipe(takeUntilDestroyed())
    .subscribe(() => {
      this.overlayRef.detach();
    });

  public readonly openForegroundNotification = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notificationsActions.showNotification),
        map((action) => {
          const portal = new ComponentPortal(NotificationsComponent);
          if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
          }
          const ref = this.overlayRef.attach(portal);
          let notification = Object.create(Notification);
          Object.defineProperties(notification, {
            title: { value: action.title, writable: false },
            ...mapValues(action.options, (item) => ({ value: item, writeable: false })),
          });
          ref.setInput('notification', notification);
          ref.setInput('timeStamp', new Date());
          ref.setInput('source', action.source);
          return portal;
        }),
      ),
    { dispatch: false },
  );

  public readonly dismissNotification = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notificationsActions.dismiss),
        tap(() => {
          this.overlayRef.detach();
        }),
      ),
    { dispatch: false },
  );
}
