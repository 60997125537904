import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Platform } from '@ionic/angular/standalone';

@Component({
  selector: 'app-root',
  template: '<router-outlet /><router-outlet name="modal" />',
  standalone: true,
  imports: [RouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {
  private platform = inject(Platform);

  public ngOnInit(): void {
    this.platform.ready().then(() => {
      setTimeout(() => {
        document.getElementById('init-app-loading')?.remove();
      }, 1000);
    });
  }
}
