import { Injectable, Injector, inject } from '@angular/core';
import { UserServiceInterface } from '@techniek-team/common';
import { BasePermission } from '../base-permission/base.permission';
import { PermissionUserInterface } from '../contracts/permission-user.interface';
import { InsufficientPermissionsError } from '../errors/insufficient-permissions.error';
import { TtPermissionConfig } from '../tt-permission.config';

interface ClassConstructor<T> {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/prefer-function-type
  new (...args: any[]): T;
}

/**
 * This service can be used to check if a user has or doesn't have permission.
 */
@Injectable()
export class PermissionService<U = PermissionUserInterface> {
  private readonly injector = inject(Injector);

  private readonly config = inject(TtPermissionConfig);

  /**
   * This is the injected permission service. This equals to the set
   * {@see TtPermissionConfig.userService} property which is retrieved using
   * the angular {@see Injector}.
   */
  private userService: UserServiceInterface<U>;

  /**
   * A Map of all roles from the {@see TtPermissionConfig.roleHierarchy}
   * property.
   */
  private roleHierarchy!: Map<string, string[]>;

  constructor() {
    this.userService = this.injector.get<UserServiceInterface<U>>(this.config.userService);
  }

  /**
   * With the method you can check if the currently logged-in user has permission
   * to what you require.
   *
   * The method returns a Promise which resolves in a boolean where true means
   * that the user has permission and false that he doesn't have permission.
   *
   * @param permissionCls This is a ClassConstructor to a Permission class which
   *                      extends the {@see BasePermission}. Which includes a set
   *                      of permission statements divided by the subject.
   * @param subject       A Permission class can contain multiple subjects to check
   *                      the permission of. This parameter states which subjects to
   *                      check.
   * @param extraArgs     For some permission checks you may need additional data.
   *                      All properties from the third property on are directly
   *                      passed to the Permission class.
   *
   * @example
   * ```typescript
   * this.permissionService.isGranted(
   *   AssignmentPermission,
   *   'REMOVE_SLOT_FROM_ASSIGNMENT',
   *   this.assignment,
   * ).then(isGranted =>
   *   // code is required permission
   * );
   * ```
   */
  public async isGranted<T extends BasePermission<U>, S extends keyof T['subjects']>(
    permissionCls: ClassConstructor<T>,
    subject: S,
    ...extraArgs: unknown[]
  ): Promise<boolean> {
    const user: U | undefined = await this.userService.getUser();
    if (!user) {
      return false;
    }

    const permission: T = this.createPermissionClass(
      permissionCls,
      user,
      await this.userService.getRoles().then((roles) => new Set(roles)),
    );

    try {
      return permission.validate(subject as string, ...extraArgs);
    } catch (error) {
      if (error instanceof InsufficientPermissionsError) {
        return false;
      }
      throw error;
    }
  }

  /**
   * With the method you can check if the currently logged-in user DOESN't have
   * permission to what you require.
   *
   * The method internally uses the {@see isGranted} method and returns exactly the
   * opposite of the isGranted method.
   *
   * @param permissionCls This is a ClassConstructor to a Permission class which
   *                      extends the {@see BasePermission}. Which includes a set
   *                      of permission statements divided by the subject.
   * @param subject       A Permission class can contain multiple subjects to check
   *                      the permission of. This parameter states which subjects to
   *                      check.
   * @param extraArgs     For some permission checks you may need additional data.
   *                      All properties from the third property on are directly
   *                      passed to the Permission class.
   *
   * @example
   * ```typescript
   * if (await this.permissionService.isDenied(SlotPermission, 'UPDATE', { ...this.slot })) {
   *   this.editSlotForm.disable();
   *   return;
   * }
   * ```
   */
  public isDenied<T extends BasePermission<U>, S extends keyof T['subjects']>(
    permissionCls: ClassConstructor<T>,
    subject: S,
    ...extraArgs: unknown[]
  ): Promise<boolean> {
    return this.isGranted(permissionCls, subject, ...extraArgs).then((isGranted) => {
      return !isGranted;
    });
  }

  /**
   * Check if the user has the given role.
   */
  public async is(role: string): Promise<boolean> {
    const roles: Set<string> = await this.userService.getRoles().then((item) => new Set(item));
    const roleHierarchy: Set<string> = this.createRoleSet(roles, this.createRoleHierarchyMap());

    return roleHierarchy.has(role);
  }

  /**
   * Check if the user has one of the given roles.
   */
  public async isOneOf(roles: string[]): Promise<boolean> {
    const promises: Promise<boolean>[] = [];

    for (let role of roles) {
      promises.push(this.is(role));
    }

    const results: boolean[] = await Promise.all(promises);
    return !!results.filter((result: boolean) => result).length;
  }

  /**
   * Internal method to create an instance of the given ClassConstructor and
   * set the base property needed for validation.
   *
   * @param permissionCls The instance type to create an instance from.
   * @param user          The User record to set in {@see BasePermission.user}.
   * @param roles         The Set of roles of the given user. This set is extend using
   *                      the Role Hierarchy to an extended set in {@see BasePermission.roles}.
   */
  private createPermissionClass<T extends BasePermission<U>>(
    permissionCls: ClassConstructor<T>,
    user: U,
    roles: Set<string>,
  ): T {
    //eslint-disable-next-line new-cap
    const permission: BasePermission<U> = new permissionCls(this.injector);
    permission.user = user;
    permission.roleHierarchy = this.createRoleHierarchyMap();
    permission.roles = this.createRoleSet(roles, permission.roleHierarchy);
    return permission as T;
  }

  /**
   * This internal method creates a Map of all roles from the
   * {@see TtPermissionConfig.roleHierarchy} property.
   *
   * The result is a Map where the index is the top most key of the object.
   * The value is an array of strings containing at least one string.
   */
  public createRoleHierarchyMap(): Map<string, string[]> {
    if (this.roleHierarchy) {
      return this.roleHierarchy;
    }

    //@ts-ignore
    this.roleHierarchy = new Map<string, string[]>(
      Object.entries<string | string[]>(this.config.roleHierarchy ?? {}).map((tuple) => {
        if (Array.isArray(tuple[1])) {
          return tuple as [string, string[]];
        }

        return [tuple[0], [tuple[1]]] as [string, string[]];
      }),
    );

    return this.roleHierarchy;
  }

  /**
   * Create a full set containing all the roles the user has including
   * all the roles he gets because of the Role hierarchy.
   * @param roles         The roles of the logged-in user.
   * @param roleHierarchy A mapped version of {@see TtPermissionConfig.roleHierarchy}
   *                      property created through {@see createRoleHierarchyMap}.
   */
  public createRoleSet(roles: Set<string>, roleHierarchy: Map<string, string[]>): Set<string> {
    for (let [key, value] of roleHierarchy.entries()) {
      if (roles.has(key)) {
        roles = this.addRole(roles, value, roleHierarchy);
      }
    }
    return roles;
  }

  /**
   * Helper method used in {@see createRoleSet} to add a role or roles from the
   * Role Hierarchy to the set of roles of the users.
   *
   * @param roles             The current set of roles. Role from the user and already included role hierarchy roles.
   * @param roleHierarchy     The current item of the mapped roleHierarchy to add the role set.
   * @param completeHierarchy The complete role hierarchy which is can be needed to added nested
   *                          roles in the role hierarchy.
   * @private
   */
  private addRole(
    roles: Set<string>,
    roleHierarchy: string[],
    completeHierarchy: Map<string, string[]>,
  ): Set<string> {
    for (let item of roleHierarchy) {
      if (completeHierarchy.has(item)) {
        this.addRole(roles, completeHierarchy.get(item) as string[], completeHierarchy);
        continue;
      }
      roles.add(item);
    }
    return roles;
  }
}
