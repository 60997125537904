/* eslint-disable @typescript-eslint/no-explicit-any */
import { APP_INITIALIZER, EnvironmentProviders, ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { browserTracingIntegration, init, replayIntegration, TraceService } from '@sentry/angular';
import { Integration } from '@sentry/types';
import { UserInterface } from '@techniek-team/oauth';
import { SentryErrorHandler } from './sentry-error-handler.service';
import { SentryEnvironment, SentryWebConfig } from './sentry-web.config';

interface TracingObject {
  type: 'withTracing';
  integrations: Integration[];

  providers: (Provider | EnvironmentProviders)[];
}

export function withTracing(
  options: { environment: SentryEnvironment } = { environment: SentryEnvironment.LOCAL },
): TracingObject {
  return {
    type: 'withTracing',
    providers: [
      { provide: TraceService, deps: [Router] },
      {
        provide: APP_INITIALIZER,
        useFactory: (): Function => (): void => {
          /* empty */
        },
        deps: [TraceService],
        multi: true,
      },
    ],
    integrations: [replayIntegration(), browserTracingIntegration()],
  };
}

//eslint-disable-next-line max-lines-per-function
export function provideSentry<T extends UserInterface>(
  options: SentryWebConfig,
  ...features: (
    | (Provider | EnvironmentProviders)[]
    | {
        type: string;
        integrations: Integration[];
        providers: (Provider | EnvironmentProviders)[];
        init?: (passedOptions: any) => void;
        options?: any;
      }
  )[]
) {
  options = { ...new SentryWebConfig(), ...options };

  let initFn: ((options: any, originalInit: (options: any) => void) => void) | undefined;
  const featureProviders = [];
  const integrations = [];
  for (let feature of features) {
    if (Array.isArray(feature)) {
      featureProviders.push(...feature);
      continue;
    }
    switch (feature.type) {
      case 'withSentry':
        initFn = feature.init;
        options = { ...options, ...feature.options };
        break;
      case 'withTracing':
        options.enableTracing = true;
        break;
    }
    featureProviders.push(...feature.providers);
    integrations.push(...feature.integrations);
  }
  if (initFn) {
    initFn(
      {
        ...options,
        enabled: true,
        defaultIntegrations: false,
        integrations: integrations,
      },
      init,
    );
  } else {
    init({
      ...options,
      enabled: true,
      defaultIntegrations: false,
      integrations: integrations,
    });
  }

  return [
    { provide: SentryWebConfig, useValue: options },
    { provide: SentryErrorHandler },
    { provide: ErrorHandler, useExisting: SentryErrorHandler },
    ...featureProviders,
  ];
}
