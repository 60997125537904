import { Routes } from '@angular/router';
import { oauthAuthenticationGuard, pkceAuthentication } from '@techniek-team/oauth';

export const ROUTES: Routes = [
  {
    path: 'login',
    loadComponent: () => import('@school-dashboard/login-page').then((c) => c.LoginPage),
    canActivate: [pkceAuthentication],
  },
  {
    path: '',
    canActivate: [pkceAuthentication, oauthAuthenticationGuard],
    loadComponent: () => import('./app/app.component').then((c) => c.AppComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        loadChildren: () => import('@school-dashboard/home').then((m) => m.ROUTES),
      },
      {
        path: 'leerlingen',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        loadChildren: () => import('@school-dashboard/pupil-overview').then((m) => m.ROUTES),
      },
      {
        path: 'pupils',
        redirectTo: 'leerlingen',
      },
      {
        path: 'trajecten',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        loadChildren: () => import('@school-dashboard/tracks-overview').then((m) => m.ROUTES),
      },
      {
        path: 'trajecten/:variant',
        loadChildren: () =>
          import('@school-dashboard/practise-track-progress').then((m) => m.ROUTES),
      },
      {
        path: 'tracks',
        redirectTo: 'trajecten',
      },
      {
        path: 'diensten',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        loadChildren: () => import('@school-dashboard/services-overview').then((c) => c.ROUTES),
      },
      {
        path: 'services',
        redirectTo: 'diensten',
      },
      {
        path: '**',
        redirectTo: '/home',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/home',
  },
  {
    path: 'track/:track',
    loadComponent: () => import('@school-dashboard/track-modal').then((c) => c.TrackModalComponent),
    outlet: 'modal',
    loadChildren: () => import('@school-dashboard/track-modal').then((c) => c.ROUTES),
  },
];
