import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Notification, notificationsActions } from './+state/notifications.actions';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private readonly store = inject(Store);

  public pushNotification(
    title: Notification['title'],
    {
      source,
      ...options
    }: Notification['options'] & Omit<Omit<Notification, 'title'>, 'options'> = {},
  ): void {
    this.store.dispatch(
      notificationsActions.showNotification({ title: title, source: source, options: options }),
    );
  }

  public dismiss() {
    this.store.dispatch(notificationsActions.dismiss());
  }
}
