import { PermissionUserInterface } from './contracts/permission-user.interface';
import { PermissionService } from './service/permission.service';
import { TtPermissionConfig } from './tt-permission.config';

/**
 * This module is intended to give a permission service similar to the Symfony
 * security component voter system.
 *
 * It comes with a PermissionService containing the methods isGranted and isDenied
 * to which you supply a {@see BasePermission} class (voter), a subject and any optional data
 * that you might need in your {@see BasePermission} class.
 *
 * This module also supplies a {@see IsGrantedDirective} for use within your html templates.
 * For information about the forRoot config options {@see TtPermissionConfig}
 *
 * @example
 * ```typescript
 * @NgModule({
 *   declarations: [
 *     AppComponent
 *   ],
 *   imports: [
 *     TtPermissionModule.forRoot({
 *       roleHierarchy: {
 *         ROLE_SKOLEO_TT_PLANNER_ADMIN: [
 *           'admin',
 *           'ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT',
 *           'ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM',
 *         ],
 *         ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT: 'locationManager',
 *         ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM: 'schedulingTeam',
 *       },
 *       userService: UserService,
 *     }),
 *   ],
 *   bootstrap: [AppComponent],
 * })
 * export class AppModule {}
 * ```
 */
export function provideTtPermissions<T extends PermissionUserInterface>(
  options: TtPermissionConfig<T>,
) {
  options = { ...new TtPermissionConfig(), ...options };

  return [{ provide: TtPermissionConfig, useValue: options }, PermissionService];
}
