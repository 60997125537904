import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, input, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/pro-light-svg-icons';
import { IonButton, IonIcon, IonRippleEffect } from '@ionic/angular/standalone';
import { Action, Store } from '@ngrx/store';
import { M3Duration, M3Easing } from '@techniek-team/common';
import { secondsToMilliseconds } from 'date-fns';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    CommonModule,
    IonRippleEffect,
    FaIconComponent,
    IonRippleEffect,
    NgOptimizedImage,
    DatePipe,
    IonButton,
    IonIcon,
    RouterLink,
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'aria-label': 'notification',
    '(mouseenter)': 'pauseTimer.set(true)',
    '(mouseleave)': 'pauseTimer.set(false)',
  },
  animations: [
    trigger('expanded', [
      transition('* => closed', [
        animate(
          M3Duration.MEDIUM + ' ' + M3Easing.EMPHASIZED_ACCELERATE,
          style({ height: '2.35rem' }),
        ),
      ]),
      transition('* => open', [
        animate(
          M3Duration.MEDIUM + ' ' + M3Easing.EMPHASIZED_ACCELERATE,
          style({ height: 'min-content' }),
        ),
      ]),
    ]),
  ],
})
export class NotificationsComponent {
  private readonly notificationsService = inject(NotificationsService);

  protected store = inject(Store);

  private readonly router = inject(Router);

  protected isExpanded = signal<boolean>(false);

  public notification = input.required<
    Notification & {
      data: {
        actions?: {
          action: string | Action<string>;
          title: string;
          icon?: string;
          type?: 'url' | 'command';
        };
      };
    }
  >();

  public timeStamp = input.required<Date>();

  public source = input<string>();

  protected readonly faChevronDown = faChevronDown;

  protected readonly faChevronUp = faChevronUp;

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected timeoutId?: any;

  protected dismissAfter = effect(() => {
    if (!this.notification().requireInteraction && !this.pauseTimer()) {
      this.timeoutId = setTimeout(
        () => {
          this.dismiss();
        },
        this.notification().data?.timeout ?? secondsToMilliseconds(10),
      );
    }
  });

  protected clearTimeout = effect(() => {
    if (this.pauseTimer()) {
      clearTimeout(this.timeoutId);
    }
  });

  public dismiss() {
    this.notificationsService.dismiss();
  }

  public pauseTimer = signal(false);

  protected readonly faXmark = faXmark;

  public triggerAction(action: {
    action: string | Action<string> | (string | Action<string>)[];
    title: string;
    icon?: string;
    type?: 'url' | 'command';
  }): Promise<boolean | void> {
    if (Array.isArray(action.action)) {
      const ngrxActions = action.action.filter(
        (ngrxAction) => typeof ngrxAction !== 'string' && 'type' in ngrxAction,
      );
      for (const ngrxAction of ngrxActions) {
        this.store.dispatch(ngrxAction);
      }
      const url = action.action.find((item) => typeof item === 'string');

      if (url) {
        this.dismiss();
        return this.router.navigate([url]).catch((error) => console.error(error));
      }
      this.dismiss();
      return Promise.resolve(true);
    }
    if (!action.type || action.type === 'url') {
      this.dismiss();
      return this.router.navigate([action.action as string]).catch((error) => console.error(error));
    }
    if (action.type === 'command') {
      this.store.dispatch(action.action as Action<string>);
      this.dismiss();
      return Promise.resolve(true);
    }
    this.dismiss();
    return Promise.resolve(false);
  }
}
